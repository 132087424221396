import React from 'react';
import { IllustrativeIcon } from '../../atoms/icons/illustrative-icon/illustrative-icon';
import { StructuredProps } from '../../types';

const Data: StructuredProps = {
  alignment: 'center',
  heading: 'Improve your metabolic health with a team that understands you.',
  items: [
    {
      id: 'we-look-at-your-health-history-from-every-angle',
      heading: 'We look at your health history from every angle.',
      description:
        'Our clinicians take the time to learn about you, in person or virtually, with an in-depth assessment to understand how your health history, genetics, and lifestyle affect your metabolic health.',
      illustration: <IllustrativeIcon color="sage" name="personal" />
    },
    {
      id: 'we-run-advanced-diagnostic-testing',
      heading: 'We run advanced diagnostic testing.',
      description:
        'We recommend diagnostic tests to determine your overall metabolic health, assessing your blood pressure, inflammatory markers, and insulin levels to get a whole-body snapshot of your health.',
      illustration: <IllustrativeIcon color="sage" name="testing" />
    },
    {
      id: 'we-prescribe-holistic-personalized-plans',
      heading: 'We prescribe holistic, personalized plans.',
      description:
        'Designed to treat your unique symptoms and conditions, the plan your clinician and health coach create for you has everything you need to optimize your nutrition, increase movement, improve sleep quality, and manage stress.',
      illustration: <IllustrativeIcon color="sage" name="teamCollab" />
    }
  ]
};

export default Data;
