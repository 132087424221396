import React from 'react';
import {
  ConditionsCollapsible,
  FeaturedArticles,
  Hero,
  Module,
  SingleQuote,
  StatisticStructuredBox,
  Structured
} from '../../redesign';
import { CliniciansHalfAndHalf } from '../../redesign/pre-built-components';
import p3ImproveYourMetabolicHealth from '../../redesign/content/metabolic-heart-rate/p3-improve-your-metabolic-health';
import p1MetabolicAndHeartHealth from '../../redesign/content/metabolic-heart-rate/p1-metabolic-and-heart-health.json';
import p1PoorMetabolicHealth from '../../redesign/content/metabolic-heart-rate/p1-poor-metabolic-health.json';
import p2TheFivePillars from '../../redesign/content/metabolic-heart-rate/p2-the-five-pillars.json';
import p5ForTheFirstTime from '../../redesign/content/metabolic-heart-rate/p5-for-the-first-time.json';
import p7BlogPostContent from '../../redesign/content/metabolic-heart-rate/p7-featured-articles.json';
import { default as SEO } from '../../components/SEO';
import { Layout } from '../../redesign';

export default function MetabolicHealthRate() {
  return (
    <Layout>
      <SEO
        pageTitle="Holistic Heart Health | High Blood Pressure & Diabetes Care | Parsley Health"
        pageDescription="Discover a different approach to metabolic health issues. Parsley Health's clinicians and treatments bring a holistic view to diabetes, high blood pressure, and other aspects of heart health."
      />
      <Module>
        <Hero {...p1MetabolicAndHeartHealth} />
      </Module>
      <Module marginTop="lg">
        <StatisticStructuredBox {...p1PoorMetabolicHealth} />
      </Module>
      <Module>
        <ConditionsCollapsible {...p2TheFivePillars} />
      </Module>
      <Module>
        <Structured {...p3ImproveYourMetabolicHealth} />
      </Module>
      <Module>
        <SingleQuote {...p5ForTheFirstTime} />
      </Module>
      <Module>
        <CliniciansHalfAndHalf
          ctaTrackingLabel="Meet Our Clinicians button on the Metabolic Heart Rate page - Stress-free online holistic care module"
          eyebrow="Improve your metabolic health naturally"
        />
      </Module>
      <Module>
        <FeaturedArticles {...p7BlogPostContent} />
      </Module>
    </Layout>
  );
}
